'use client';

import analyticsStore from '~/analytics/Analytics.store';
import deviceWrapperStore from '~/components/atoms/DeviceWrapper/DeviceWrapper.store';
import shadowStore from '~/components/molecules/Shadow/Shadow.store';
import tubeAssetsStore from '~/components/molecules/Tube/Tube.store';
import stickyBackgroundStore from '~/components/organisms/modules/StickyBackground/StickyBackground.store';
import Dictionary from '~/data/stores/Dictionary';
import UserStore from '~/state/user';
import { getAccessTokenFromCookies } from '~/utils/auth/getAccessTokenFromCookies';
import { hasAccessTokenExpired } from '~/utils/auth/hasAccessTokenExpired';

import { ClientHydrationProps } from './ClientHydration.types';

/**
 * This component is used only on the client in order to feed the global data to components
 * that need it but that are wrapped in client components, making it impossible to fetch
 * from them directly.
 * @param shadows The images necessary to create the shadows
 * @param deviceWrappers The images necessary to create the device wrappers
 * @param dictionary The dictionary store
 * @param stickyBackground The background image for the sticky background component
 * @param variantInfo The id of a given variant for use in Enterprise targeting and A/B split tests
 * @param tubeAssets The assets for the tube component
 * @returns Null
 */
const ClientHydration = ({
  shadows,
  deviceWrappers,
  dictionary,
  stickyBackground,
  tubeAssets,
  variantInfo,
}: ClientHydrationProps) => {
  UserStore.getState().setPageSessionStartTimestamp(Date.now());
  const accessToken = getAccessTokenFromCookies();
  if (accessToken) {
    const isTokenExpired = hasAccessTokenExpired(accessToken);

    if (!isTokenExpired) {
      UserStore.getState().setIsLoggedIn(true);
    }
  }

  if (variantInfo) {
    analyticsStore.getState().setVariantName(variantInfo.variantName);
    analyticsStore.getState().setVariantType(variantInfo.variantType);
    analyticsStore.getState().setVariantId(variantInfo.variantId);
  }

  if (shadows) {
    shadowStore.getState().setShadows(shadows);
  }

  if (deviceWrappers) {
    deviceWrapperStore.getState().setDeviceWrappers(deviceWrappers);
  }

  if (dictionary) {
    Dictionary.initialize(dictionary);
  }

  if (stickyBackground) {
    stickyBackgroundStore.getState().setBackground(stickyBackground);
  }

  if (tubeAssets) {
    tubeAssetsStore.getState().setAssets(tubeAssets);
  }

  return null;
};

export default ClientHydration;
