import { isPast, subMinutes } from 'date-fns';
import { jwtDecode } from 'jwt-decode';

/**
 * This method for getting the expiration Date of the access token
 *
 * @returns true if the access token has expired
 */
export const hasAccessTokenExpired = (accessToken?: string | null): boolean => {
  try {
    if (!accessToken) {
      throw new Error('No access token');
    }
    // Buffer to avoid race conditions where the token is valid now but not on client in a few seconds
    const decodedToken = jwtDecode(accessToken);
    const expires = decodedToken.exp ?? 0;
    const expiresAt = new Date(expires * 1000);
    if (!expiresAt) {
      throw new Error('No expiration found in access token');
    }
    const safeExpiresAt = subMinutes(expiresAt, 2);

    // access token has expired
    if (isPast(safeExpiresAt)) {
      return true;
    }

    return false;
  } catch {
    return true;
  }
};
